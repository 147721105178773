"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var PluginType;
(function (PluginType) {
    PluginType["SEARCH"] = "search";
    PluginType["MATH"] = "math";
})(PluginType || (PluginType = {}));
// In order to save bandwidth, we load plugins only when needed
let loaded_plugins = {};
// Load math plugin when document contains math content
const has_math = document.querySelectorAll('div.math').length > 0;
if (has_math) {
    ensure_plugin_loaded(PluginType.MATH);
}
// Setup search trigger
const search_btn = document.getElementById('search_btn');
const search_field = document.getElementById('search_field');
const search_results = document.getElementById('search_result');
function ensure_plugin_loaded(plugin) {
    return __awaiter(this, void 0, void 0, function* () {
        if (plugin in loaded_plugins) {
            return;
        }
        let _ = yield import(`./plugins/${plugin}`);
        loaded_plugins[plugin] = true;
    });
}
if (search_btn != null && search_field != null && search_results != null) {
    search_btn.addEventListener('click', (event) => {
        ensure_plugin_loaded(PluginType.SEARCH);
        if (search_field.classList.contains('hide')) {
            search_field.classList.remove('hide');
            search_field.focus();
        }
        else {
            search_field.classList.add('hide');
            search_results.innerHTML = '';
        }
    });
}
